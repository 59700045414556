@use "../../../variables.scss" as *;

.user-info {
  display: flex;
  align-items: center;
  cursor: default;
  padding: $list-padding-vertical $list-padding-left;
  border-bottom: 1px solid $border-color;

  .user-name {
    font-size: 14px;
    color: $base-text-color;
  }

  .image-container {
    overflow: hidden;
    border-radius: 50%;
    height: 28px;
    width: 28px;
    margin-right: $list-padding-left;
    border: 1px solid $border-color;

    .user-image {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-color: #fff;
      background-size: cover;
    }
  }
}

.dx-list.user-info-list.dx-scrollable.dx-widget {
  height: auto;
}

.dx-list-item .dx-icon {
  vertical-align: middle;
  margin-right: 16px;
}

.dx-rtl .dx-list-item .dx-icon {
  margin-right: 0;
  margin-left: 16px;
}
