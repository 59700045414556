@use '../../variables.scss' as *;
@use 'sass:math';

$left-panel-width: 400px;
$right-panel-width: 360px;

.toolbar-details {
  margin-bottom: calc(#{$toolbar-margin-bottom} / 2);

  .dx-toolbar-label > div {
    @include header();
  }
}

.view-wrapper-contact-details {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: $content-padding $content-padding 0 $content-padding;

  @media only screen and (max-width: 875px) {
    .contact-name-toolbar-item {
      min-width: calc(#{$left-panel-width} + #{$right-panel-width} - 145px);
    }
  }

  .panels {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;

    .left {
      flex: 1 $left-panel-width;
      margin-top: 8px;
    }

    .right {
      flex: 1 calc(100% - #{$left-panel-width} - 20px);
      margin-top: 8px;
      min-width: 340px;
    }
  }
}
