@use "../../../variables.scss" as *;

.messages {
  .input-messages {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
  }

  .file-uploader {
    .dx-fileuploader-wrapper {
      padding: 0;

      .dx-fileuploader-input-wrapper {
        padding: 0;
      }
    }
  }

  .message-container {
    display: flex;

    &:nth-child(odd) {
      flex-direction: row-reverse;
    }

    .circle {
      width: 32px;
      height: 32px;
      border-radius: 16px;
      flex-shrink: 0;
      margin: 2px 5px;
      font-size: 12px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .message {
      background-color: $base-bg;
      padding: 10px;
      margin-bottom: 10px;

      .message-title {
        @include message-title();

        .subject {
          color: $base-text-color;
          font-weight: 700;
        }
      }

      .message-text {
        @include message-text();
      }
    }
  }
}
