@use "../../../variables.scss" as *;

.view-wrapper-scroll > .dx-scrollable-wrapper > .dx-scrollable-container > .dx-scrollable-content > .dx-scrollview-content {
  background: url('../../../../public/images/bg1.jpg');
}

.login-form {
  .form-text {
    color: $base-text-color-alpha;
  }
}

.reset-link {
  text-align: center;
  font-size: 12px;
  font-style: normal;
  margin: 6px 0 50px 0;

  a {
    cursor: pointer;
  }
}
