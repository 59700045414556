@use "../../../variables.scss" as *;
@use "sass:math";
@use "sass:color";

.calendar-list {
  width: 100%;

  .dx-list-group-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-style: none;
    flex-direction: row-reverse;

    .dx-inkripple {
      border-radius: 4px;
    }
  }

  .list-header {
    display: flex;
    vertical-align: middle;
    align-items: center;
    gap: 8px;
    color: $base-text-color;

    @include h1-header-text();
  }

  .dx-list {
    .dx-list-group-body .dx-list-item.dx-list-item-selected {
      background-color: $base-bg;
    }

    .dx-list-group:not(.dx-list-group-collapsed):not(:last-child) {
      border-style: none;
    }
  }

  .list-item {
    display: flex;
    vertical-align: middle;
    align-items: center;
    border-radius: 4px;
  }

  .list-item-text {
    padding: 0 8px;

    @include calendar-items-text();
  }

  --checkbox-color: $base-accent;

  .dx-checkbox-checked .dx-checkbox-icon {
    background-color: var(--checkbox-color);
  }
}
