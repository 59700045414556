@use "../../variables.scss" as *;
@use "sass:math";

.view-wrapper-calendar {
  position: relative;
  flex-direction: column;
  display: flex;
  height: inherit;

  .calendar-content {
    display: flex;
    height: inherit;
    gap: 16px;
    padding: $content-padding $content-padding $theme-padding;

    .left-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: $theme-padding;

      &.small {
        width: calc($calendar-width + $content-padding);
      }

      .buttons {
        display: flex;
        width: 100%;
        gap: 16px;
        padding: 0 $theme-padding;

        .dx-button {
          width: calc((100% - $theme-padding) / 2);
          font-size: 13px;
          border-radius: 4px;
        }
      }

      .calendar {
        align-self: center;
        padding: $theme-padding;
      }

      .dx-calendar {
        width: $calendar-width;
      }
    }

    .main-content {
      flex: 1;
      height: inherit;
      border: 1px grey;
      border-radius: 8px;
    }
  }

  .dx-scheduler-appointment-content {
    color: #333;
  }

  .dx-scheduler-agenda .dx-scheduler-appointment-recurrence-icon {
    color: #333;
  }

  .dx-scheduler-header {
    border-radius: 8px 8px 0 0;
    height: $scheduler-toolbar-height;
  }

  .dx-scheduler-work-space {
    border-radius: 0 0 8px 8px;
  }
}

.dx-tooltip-wrapper > .dx-popup-normal {
  background-color: $base-bg !important;
  color: $base-text-color !important;
  border-radius: 8px !important;
  max-width: 360px;
  filter: drop-shadow(0 4px 24px rgba(0, 0, 0, 0.24));
}
