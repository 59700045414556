@use "../../../variables.scss" as *;

.contact-form {
  min-height: 300px;

  .dx-form {
    .form-item-button {
      margin-left: 10px;
      margin-top: 5px;
    }
  }

  .status-editor-field {
    display: flex;
    padding-left: $list-padding-left;

    .status-contact {
      align-self: flex-end;
    }
  }
}
