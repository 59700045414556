$base-bg: var(--base-bg);
$base-bg-darken-5: var(--base-bg-darken-5);
$base-bg-darken-4: var(--base-bg-darken-4);
$base-text-color: var(--base-text-color);
$base-focus-color: var(--base-focus-color);
$side-panel-background: $base-bg-darken-5;
$background-color: $base-bg;
$border-color: var(--border-color);
$accent-color: var(--accent-color);
$background-gray-color: var(--background-gray-color);
$texteditor-edit-color: var(--texteditor-edit-color);
$texteditor-label-color: var(--texteditor-label-color);
$base-text-color-alpha: var(--base-text-color-alpha);
$base-border-color: var(--border-color);
$scheduler-toolbar-height: var(--scheduler-toolbar-height);
$tooltip-danger-button-color: var(--tooltip-danger-button-color);
$tooltip-success-button-color: var(--tooltip-success-button-color);
$button-height: var(--button-height);
$theme-padding: var(--theme-padding);

$toolbar-height: var(--toolbar-height);
$calendar-width: var(--calendar-width);
$filled-texteditor-input-horizontal-padding: var(--filled-texteditor-input-horizontal-padding);
$list-padding-left: var(--list-padding-left);
$list-padding-vertical: var(--list-padding-vertical);
$footer-left-padding: var(--footer-left-padding);

$header-toolbar-vertical-padding: 10px;
$toolbar-vertical-padding: 16px;
$content-padding: 16px;
$side-panel-min-width: var(--side-panel-min-width);
$toolbar-margin-bottom: $content-padding;

$footer-height: 70px;

@mixin separator() {
  .separator {
    height: 24px;
    width: 1px;
    background: $base-border-color;

    .dx-toolbar-menu-section & {
      height: 1px;
      width: auto;
    }
  }
}
$drop-down-gnrc-button-height: var(--drop-down-gnrc-button-height);

@mixin header {
  font-size: 22px;
  font-weight: 500;
  padding-right: 25px;
}

@mixin user-info {
  .user-info {
    margin-bottom: 20px;
    display: flex;

    .photo {
      width: 124px;
      height: 124px;
      margin-right: 20px;
      border-radius: 8px;
      background-repeat: no-repeat;
      background-size: cover;
      box-shadow: 0 2px 8px 0 #00000014;
      border: 1px solid #e0e0e0;
    }
  }

  .title {
    font-size: 12px;
    color: $texteditor-label-color;

    &:not(:first-child) {
      padding-top: 10px;
    }
  }

  .value {
    font-size: 16px;
    color: $accent-color;

    &.black {
      color: $base-text-color;
    }

    &.small {
      font-size: 12px;
    }

    .dx-icon {
      vertical-align: bottom;
      padding: 10px 12px 0 0;
    }
  }
}

@mixin message-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  color: $texteditor-label-color;
}

@mixin message-text {
  padding-top: 5px;
  line-height: 21px;
}

@mixin status-contact($status-color) {
  color: $status-color;

  &.dx-texteditor,
  &.dx-texteditor.dx-editor-filled {
    .status-editor-input[readonly] {
      color: $status-color;
      padding-left: 0;
    }
  }

  span {
    &::before {
      background-color: $status-color;
    }
  }
}

@mixin calendar-items-text {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
}

@mixin description-text($opacity) {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  opacity: $opacity;
}

@mixin h1-header-text {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
}

@mixin h2-header-text {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

@mixin subtext($opacity: 1) {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  opacity: $opacity;
}
